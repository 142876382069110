import { useCallback, useContext } from "react";
import api from "../services/api";

import { GlobalContext } from "../services/GlobalContext";
import useAuth from "./useAuth";
import { stripe } from "../services/stripe";
import moment from "moment";
import { SocketContext } from "../services/SocketContext";
import axios from "axios";
import { BASE_URL_ } from "../constants/endpoints";
import { getQueryString } from "../utils/utils";
import { useTranslation } from "react-i18next";

export default function useGlobal() {
  const { i18n } = useTranslation();
  const {
    setIsLoading,
    setPaymentMethods,
    setSubscription,
    setProduct,
    setSessions,
    setError,
    setInvoices,
    setPortalUsers,
    tokenData,
    setProvidersList,
    setProviderUserName,
    providerUserName,
    setProvider,
    provider: providerData,
    setUsers,
    subscription,
    setEndCall,
    setIsMuted,
    setLanguageSetting,
  } = useContext(GlobalContext);
  const {
    user,
    setUser,
    receiver,
    setReceiver,
    setSession,
    session,
    onFetchUser,
    setTranscripts,
    sourceLanguage,
    setReceivers,
    setSourceLanguage,
    handleType,
  } = useContext(SocketContext);
  const { userData, getUserApi } = useAuth();

  const getPaymentMethods = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(
        `/billing/payment-methods?customerId=${userData?.billing?.custId}&limit=10`
      );
      const respData = response.data;

      setPaymentMethods(respData?.data?.data || []);
      setIsLoading(false);
      return respData?.data?.data;
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.data?.message ?? error.message);
      throw new Error(error.message);
    }
  };

  const getSubscriptions = async (subId) => {
    try {
      const subs = await api.get(
        `/billing/subscription/${subId || userData?.billing?.subId}`
      );

      const subsData = await subs.data;

      const sub = subsData?.subscription ?? null;

      setSubscription(sub);
      return sub;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      throw new Error(error.message);
    }
  };

  const getProduct = async (sub) => {
    try {
      const productId = sub?.plan?.product;

      const prods = await api.get(`/billing/product/${productId}`);

      const prodsData = await prods.data;

      const prod = prodsData?.product ?? null;
      setProduct(prod);
      return prod;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      throw new Error(error.message);
    }
  };

  const createPaymentMethod = async (paymentMethod) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await api.post(
        `/billing/create-payment-method`,
        {
          name: userData?.org?.name,
          email: userData?.org?.email,
          userId: userData?.userId,
          custId: userData?.billing?.custId,
          paymentMethodId: paymentMethod.id,
          orgId: userData?.org?.Id,
        },
        headers
      );
      const pm = await response.data;
      return pm;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      throw new Error(error.message);
    }
  };

  const setDefaultPaymentMethod = async (paymentMethod) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await api.post(
        `/billing/set-default-pm`,
        {
          custId: userData?.billing?.custId,
          paymentMethodId: paymentMethod.id,
        },
        headers
      );
      const pm = await response.data;
      setIsLoading(false);
      return pm;
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.data?.message ?? error.message);
      throw new Error(error.message);
    }
  };

  const getSessions = async ({ filter, providerUserId, patient }) => {
    try {
      setIsLoading(true);
      const query = getQueryString({
        filter,
        providerUserId,
        patient,
      });
      const sessionUrl = `/user/sessions/org/${
        userData?.org?.Id ?? 1
      }?${query}`;
      const sessionsData = await api.get(sessionUrl);
      const sData = sessionsData.data.data;
      setSessions(sData);
      setIsLoading(false);
      return sData;
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.data?.message ?? error.message);
      return null;
    }
  };

  const getProviders = async ({ orgId }) => {
    try {
      setIsLoading(true);
      const providersUrl = `/providers/?orgId=${orgId ?? 1}`;
      const providersData = await api.get(providersUrl);
      const sData = providersData.data.data;
      setProvidersList(sData);
      setIsLoading(false);
      return sData;
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.data?.message ?? error.message);
      return null;
    }
  };

  const generateSOAPNote = async ({ messages, sessionId }) => {
    try {
      setIsLoading(true);
      const soapUrl = `/session/generate/SOAP`;
      const providersData = await api.post(soapUrl, {
        messages,
        sessionId,
      });
      const sData = providersData.data.data;
      setIsLoading(false);
      return sData;
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.data?.message ?? error.message);
      return null;
    }
  };

  const createCheckout = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await api.post(
        `/billing/create-checkout-session`,
        {
          lookupKey: "basic_plan_nc",
          name: userData?.org?.name,
          email: userData?.org?.email,
          userId: userData?.userId,
          custId: userData?.billing?.custId,
          orgId: userData?.org?.Id,
        },
        headers
      );
      const session = await response.data;
      const stripeApi = await stripe();

      setIsLoading(false);
      const result = await stripeApi.redirectToCheckout({
        sessionId: session.id,
      });
      if (result.error) {
        console.log(result.error);
        setError(result.error);
      }
      await getUserApi();
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
      setError(error?.response?.data?.message ?? error.message);
    }
  };

  const updateSubId = async (sessionId) => {
    try {
      await api.get(`/billing/session/${sessionId}?orgId=${userData?.org?.Id}`);
      await getUserApi();
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      // throw new Error(error.message);
    }
  };

  const getInvoices = async (limit) => {
    try {
      setIsLoading(true);
      if (!userData?.billing?.custId) {
        setIsLoading(false);
        return userData?.billing?.custId;
      }
      const invoices = await api.get(
        `/billing/invoices?custId=${userData?.billing?.custId}&limit=${limit}`
      );
      const invoicesData = invoices.data?.invoices;

      const invoice = invoicesData?.data ?? null;
      setInvoices(invoice);
      setIsLoading(false);
      return invoice;
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.data?.message ?? error.message);
      // throw new Error(error.message);
    }
  };

  const getOrg = async () => {
    try {
      setIsLoading(true);
      const org = await api.get(`/org/${userData?.org?.Id}`);
      const orgData = org.data?.data;
      setIsLoading(false);
      return orgData;
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.data?.message ?? error.message);
      // throw new Error(error.message);
    }
  };

  const updateOrg = async (payload) => {
    try {
      const org = await api.patch(`/org/${userData?.org?.Id}`, payload);
      const orgData = org.data?.data;
      return orgData;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      // throw new Error(error.message);
      return null;
    }
  };

  const getTranscripts = async ({ sessionId }) => {
    try {
      setIsLoading(true);
      const res = await api.get(`/session/transcript/all/${sessionId}`);
      setTranscripts(res.data.data);
      setIsLoading(false);
      return res.data.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
      return null;
    }
  };

  const getPortalUsers = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/user/org/${userData?.org?.Id}`);
      setPortalUsers(res.data.data);
      setIsLoading(false);
      return res.data.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
      return null;
    }
  };

  const getUsers = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/user/org/${userData?.org?.Id}?isFilter=true`);
      setUsers(res.data.data);
      setIsLoading(false);
      return res.data.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
      return null;
    }
  };

  const inviteUser = async ({ email, userType, orgId }) => {
    try {
      setIsLoading(true);
      const res = await api.post(`/user/invite`, {
        email,
        userType,
        orgId,
      });
      setIsLoading(false);
      alert(res.data?.message);
      return res.data.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
      return null;
    }
  };

  const onboardUser = async (data) => {
    try {
      setIsLoading(true);
      const res = await api.post(`/user/`, data);
      setIsLoading(false);
      alert(res.data?.message);
      return res.data.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
      return null;
    }
  };

  const updateUser = async (userId, data) => {
    try {
      setIsLoading(true);
      const res = await api.put(`/user/${userId}`, data);
      setIsLoading(false);
      return res.data.data;
    } catch (error) {
      // setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
      return null;
    }
  };

  const inviteUserVerify = async ({ token }) => {
    try {
      setIsLoading(true);
      const res = await api.get(`/user/invite/${token}`);
      setIsLoading(false);
      alert(res.data?.message);
      return res.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
      return null;
    }
  };

  const uploadRec = async ({ audio, transcriptId }) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", audio);
      const res = await api.patch(`/transcript/rec/${transcriptId}`, formData);
      setIsLoading(false);
      return res.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
      return null;
    }
  };

  const postFeedback = async ({
    feedbackText,
    transcriptionRating,
    translationRating,
    sessionId,
  }) => {
    try {
      setIsLoading(true);
      const res = await api.post(`/feedback/`, {
        feedbackText,
        transcriptionRating,
        translationRating,
        sessionId,
        userId: user?.userId,
      });
      setIsLoading(false);
      return res.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
      return null;
    }
  };

  const saveToEHR = async (payload, isSoap) => {
    try {
      setIsLoading(true);
      let encRes;
      if (!payload?.encounterid) {
        const { practiceId, deptId } = parseToken();
        encRes = await getEnc({
          sessionId: payload?.sessionId,
          patient: receiver?.ehrId,
          practiceId,
          deptId,
        });
        setSession((prev) => ({
          ...prev,
          ehrEncounterId: encRes?.encounterId,
        }));
      }
      if (!payload?.encounterid && !encRes?.encounterId) {
        throw new Error(
          "Encounter Id is required, please start the encounter and launch this session again."
        );
      }
      if (!payload?.discussionnotes) {
        throw new Error("Cannot publish empty note.");
      }
      if (!payload?.sessionId) {
        throw new Error("Missing sessionId!.");
      }

      const res = await api.post(`/athena/saveToEHR`, payload);
      setSession((prev) => {
        return {
          ...prev,
          publishedToEhr: true,
          SOAPNote: isSoap ? payload?.discussionnotes : prev?.SOAPNote,
        };
      });
      setIsLoading(false);
      return res.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
      return null;
    }
  };

  const getNote = ({ encounter, data }) => {
    let note = "";
    if (encounter?.vitals) {
      note += document.getElementById("vitals_details").outerHTML;
      note += "<br />";
    }
    if (encounter?.hpi) {
      note += document.getElementById("hpi_details").outerHTML;
      note += "<br />";
    }
    if (encounter?.ros) {
      note += document.getElementById("ros_details").outerHTML;
      note += "<br />";
    }
    if (encounter?.assessment) {
      note += document.getElementById("assessment_details").outerHTML;
      note += "<br />";
    }
    if (encounter?.orders?.length) {
      note += document.getElementById("prescription_details").outerHTML;
      note += "<br />";
    }
    if (encounter?.patientgoals) {
      note += document.getElementById("patient_instructions_details").outerHTML;
      note += "<br />";
    }
    if (encounter?.followup) {
      note += document.getElementById("followup_details").outerHTML;
      note += "<br />";
    }
    data?.forEach((element, idx) => {
      note += document.getElementById(`transcript_${idx}`).outerHTML;
      note += "<br />";
    });
    return note;
  };

  // Function to convert blob to WAV format
  const blobToWav = async (blob) => {
    return new Promise((resolve, reject) => {
      try {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const fileReader = new FileReader();

        fileReader.onload = async function () {
          try {
            const arrayBuffer = this.result;
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            const audioData = audioBuffer.getChannelData(0); // Get the mono channel
            const numberOfChannels = 1;
            const sampleRate = audioBuffer.sampleRate;
            const bitsPerSample = 16;

            const dataLength =
              ((audioData.length * bitsPerSample) / 8) * numberOfChannels;
            const buffer = new ArrayBuffer(44 + dataLength);
            const view = new DataView(buffer);

            // Set WAV file header
            const writeString = (offset, string) => {
              for (let i = 0; i < string.length; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
              }
            };

            writeString(0, "RIFF");
            view.setUint32(4, 36 + dataLength, true);
            writeString(8, "WAVE");
            writeString(12, "fmt ");
            view.setUint32(16, 16, true);
            view.setUint16(20, 1, true);
            view.setUint16(22, numberOfChannels, true);
            view.setUint32(24, sampleRate, true);
            view.setUint32(
              28,
              (sampleRate * numberOfChannels * bitsPerSample) / 8,
              true
            );
            view.setUint16(32, (numberOfChannels * bitsPerSample) / 8, true);
            view.setUint16(34, bitsPerSample, true);
            writeString(36, "data");
            view.setUint32(40, dataLength, true);

            // Float32 to PCM
            const offset = 44;
            const writeFloat = (output, offset, input) => {
              for (let i = 0; i < input.length; i++, offset += 2) {
                const s = Math.max(-1, Math.min(1, input[i]));
                output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
              }
            };

            writeFloat(view, offset, audioData);

            resolve(new Blob([view], { type: "audio/wav" }));
          } catch (error) {
            console.error(error, "err inner exception");
            reject("Something went wrong !");
          }
        };

        fileReader.readAsArrayBuffer(blob);
      } catch (error) {
        console.log(error, "err inner");
      }
    });
  };

  const reduceNoise = async ({ audio, language }) => {
    try {
      // Convert audio to WAV format
      const wavBuffer = await blobToWav(audio);
      const formData = new FormData();
      formData.append("file", new Blob([wavBuffer], { type: "audio/wav" }));
      formData.append("language", language);
      const res = await axios.post(
        `${BASE_URL_}/noise/reduce-noise/`,
        formData
      );
      // const res = await axios.post(
      //   `https://dev.caresms.io/noise/reduce-noise/`,
      //   formData
      // );
      return res.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      throw new Error(error);
    }
  };

  const getSessionById = async ({ sessionId, auth }) => {
    try {
      const res = await api.get(`/session/${sessionId}`);
      const { patient, provider, staff, ...rest } = res.data.data;
      // console.log(rest, "data", patient, provider);
      const isCompleted = rest?.sessionStatus === "COMPLETED";
      const isProvider = auth;
      const isPatient = !auth;

      let providerRes = providerData;
      // console.log(providerUserName, "providerUserName");
      if (auth && providerUserName && !providerRes) {
        const userName = providerUserName;
        providerRes = await getProviderByUsername({
          username: userName,
        });
        if (providerRes?.billing?.subId && !subscription) {
          await getSubscriptions(providerRes?.billing?.subId);
        }
      }
      const userData = isProvider
        ? providerRes?.userType === "ADMIN"
          ? staff
          : provider
        : patient;
      setSourceLanguage(userData?.language || "en");
      const receiverData = isProvider ? patient : provider;
      providerRes?.ehrUsername && setProviderUserName(providerRes?.ehrUsername);
      userData && setUser({ ...userData, language: sourceLanguage });
      receiverData && setReceiver(receiverData);
      setReceivers(
        isProvider ? [receiverData] : [staff, provider].filter(Boolean)
      );
      // console.log(userData, "user", receiverData);
      const users = [
        {
          language: patient.language || "en",
          userId: patient.userId,
          name: patient.firstName,
          isOnline: isPatient,
        },
        {
          language: provider.language || "en",
          userId: provider.userId,
          name: provider.lastName,
          isOnline: providerRes?.userType === "PROVIDER",
        },
      ];
      staff &&
        users.push({
          language: staff.language || "en",
          userId: staff.userId,
          name: staff.lastName,
          isOnline: providerRes?.userType === "ADMIN",
        });
      if (!isCompleted) {
        onFetchUser(userData, sessionId, users, isCompleted, !isProvider);
      }
      setSession(rest);
      return {
        patient,
        ...rest,
      };
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      return {};
    }
  };

  const getEnc = async ({ sessionId, patientId, practiceId, deptId }) => {
    try {
      const encRes = await api.get(
        `/athena/getEnc?sessionId=${sessionId}&practiceid=${
          practiceId ?? 1959447
        }&departmentid=${deptId ?? 163}&patientId=${patientId}&date=${
          session?.sessionDate
            ? moment(session?.sessionDate).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD")
        }`
      );
      return encRes.data.data;
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      return null;
    }
  };

  const getSession = async ({ sessionId, search }) => {
    try {
      setIsLoading(true);
      // Parse the query parameters from the URL
      const params = new URLSearchParams(search);

      const auth = params.get("auth");
      if (auth) {
        handleType("Provider");
        setIsMuted(false);
      } else {
        handleType("Patient");
        setIsMuted(false);
      }
      const { practiceId, deptId } = parseToken();
      const { patient, ...rest } = await getSessionById({
        sessionId,
        auth,
      });
      const isCompleted = rest?.sessionStatus === "COMPLETED";
      let langData = localStorage.getItem(`${sessionId}-lang`);
      if (!langData && auth && !isCompleted) {
        setLanguageSetting(true);
      }
      if (isCompleted && auth) {
        setEndCall(isCompleted);
      }
      i18n.changeLanguage(auth ? "en" : patient.language || "en");
      if (
        (rest?.ehrEncounterId === undefined || rest?.ehrEncounterId === null) &&
        auth
      ) {
        const encRes = await getEnc({
          sessionId,
          patientId: patient?.ehrId,
          practiceId,
          deptId,
        });
        setSession((prev) => ({
          ...prev,
          ehrEncounterId: encRes?.encounterId,
          previousEncounterNote: encRes?.note,
        }));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.log(error.message);
      setError(error?.response?.data?.message ?? error.message);
    }
  };

  const getToken = async ({}) => {};

  const getProviderByUsername = async ({ username }) => {
    try {
      const providerRes = await api.get(`/providers/userName/${username}`);
      setProvider((prev) =>
        prev?.userType === "ADMIN"
          ? { ...prev, ehrId: providerRes.data?.data.ehrId }
          : providerRes.data?.data
      );
      return providerRes.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const parseToken = (token) => {
    if (!token && !tokenData) {
      return {};
    }
    const { patient, ah_department, encounter, ah_practice, username } =
      token || tokenData;
    const patientId = patient?.split("-")[2];
    const deptId = ah_department?.split("-")[2];
    const encId = encounter?.split("-")[2];
    const practiceId = ah_practice?.split("-")[2];
    const userName = username?.split("-")[1];

    return {
      patientId,
      username,
      userName,
      deptId,
      encId,
      practiceId,
    };
  };

  const validateSubscription = useCallback((subscription) => {
    if (!subscription) {
      return false; // made dummy subscription for testing
    }

    // console.log(subscription.status, "subscription.status");
    if (subscription.status === "active") {
      return true;
    }
    return false;
  }, []);

  return {
    getPaymentMethods,
    getSubscriptions,
    getProduct,
    createPaymentMethod,
    setDefaultPaymentMethod,
    getSessions,
    createCheckout,
    updateSubId,
    getTranscripts,
    getEnc,
    getSession,
    getInvoices,
    getPortalUsers,
    inviteUser,
    inviteUserVerify,
    onboardUser,
    uploadRec,
    reduceNoise,
    parseToken,
    getProviders,
    generateSOAPNote,
    postFeedback,
    saveToEHR,
    getNote,
    getOrg,
    updateOrg,
    getProviderByUsername,
    getUsers,
    validateSubscription,
    updateUser,
  };
}
